import { useTheme } from "@emotion/react"
import { LoadingButton } from "@mui/lab"
import { Box, Button, CircularProgress, Divider, Typography } from "@mui/material"
import { IconLoader } from "@tabler/icons-react"
import { useCallback, useEffect, useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { toast } from "react-toastify"
import { Dialog } from "~/components/Dialog"
import { Select } from "~/components/Form/Select"
import { CustomerCalculateNewDueDateInvoiceIdQuery, useCustomerCalculateNewDueDateInvoiceIdQuery, useCustomerRequestInvoiceSecondCopyMutation } from "~/graphql/types"
import { formatDate, formatMoney, transformMoney, Unarray } from "~/utils"
import { Option } from "~/utils/options"

type SecondInvoiceGenerateModalProps = {
  onClose: Function
  isVisible: boolean
  invoiceId: null | string
}

type FormData = {
  dueDate: string
}

type selectedDueDate = Unarray<CustomerCalculateNewDueDateInvoiceIdQuery['customerCalculateNewDueDateInvoiceId']['newDueDateCalculated']>

export const SecondInvoiceGenerateModal: React.FC<SecondInvoiceGenerateModalProps> = ({ isVisible, onClose, invoiceId }) => {
  const theme = useTheme()
  const formMethods = useForm<FormData>()
  const { dueDate: watchDueDate } = useWatch({ control: formMethods.control })
  const [selectedDueDate, setSelectedDueDate] = useState<null | selectedDueDate>(null)

  const onCloseModal = useCallback(() => {
    onClose()
    setSelectedDueDate(null)
    formMethods.reset()
  }, [])

  const { loading: loadingCalculateNewDueDate, data: calculateNewDateData } = useCustomerCalculateNewDueDateInvoiceIdQuery({
    variables: {
      invoiceId: invoiceId || ''
    },
    onError(error) {
      toast.error(error.message)
      onCloseModal()
    },
  })

  const [requestInvoiceCopy, { loading: requestInvoiceCopyIsLoading }] = useCustomerRequestInvoiceSecondCopyMutation({
    onCompleted() {
      toast.success('A 2ª via será enviada ao seu email!')
      onCloseModal()
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const datesByData = calculateNewDateData?.customerCalculateNewDueDateInvoiceId.newDueDateCalculated || []
  const newDueDateOptions: Option[] = datesByData.map(item => ({ label: formatDate(item.dueDate), value: item.dueDate }))

  useEffect(() => {
    if(datesByData.length > 0) {
      formMethods.setValue('dueDate', datesByData[0].dueDate)
      setSelectedDueDate(datesByData[0])
    }
  }, [datesByData])

  useEffect(() => {
    if(watchDueDate && datesByData.length > 0) {
      const newDueDate = datesByData.find(item => item.dueDate === watchDueDate)
      setSelectedDueDate(newDueDate || null)
    }
  }, [watchDueDate])

  const onSubmit = useCallback((data: FormData) => {
    requestInvoiceCopy({
      variables: {
        params: {
          dueDate: data.dueDate,
          invoiceId: invoiceId || ''
        }
      }
    })
  }, [invoiceId])

  return (
    <Dialog loading={loadingCalculateNewDueDate} onClose={() => onCloseModal()} title="Gerar 2ª via de fatura" isVisible={isVisible}>
    
        <FormProvider {...formMethods}>
        <Divider sx={{margin: '.4rem 0 1.4rem 0'}} />
        <form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
          <Box sx={{display: 'flex', flexFlow: 'column', gap: '1rem'}}>
            <Typography sx={{ color: theme.palette.grey[800] }}>Selecione a data de vencimento da 2ª via:</Typography>
            <Select name='dueDate' options={newDueDateOptions} />
            <Box sx={{display: 'flex', flexFlow: 'column', gap: '.2rem'}}>
              <Typography sx={{ color: theme.palette.grey[800] }}>O novo valor da fatura será de <Typography sx={{ fontWeight: 500, display: 'inline' }}>
                {formatMoney(transformMoney(selectedDueDate?.amountCents || 0, 'toReal'))}</Typography>
              </Typography>
              <Typography sx={{ color: theme.palette.grey[600], fontWeight: 500 }}>
                ({formatMoney(transformMoney(((selectedDueDate?.fineAmountCents || 0) + (selectedDueDate?.interestAmountCents || 0)), 'toReal'))} de multas e juros)
              </Typography>
            </Box>
          </Box>

          <Divider sx={{margin: '1.4rem'}} />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '1rem' }}>
            <Button onClick={() => onCloseModal()} color='secondary'>Cancelar</Button>
            <Button
              type="submit"
              disabled={requestInvoiceCopyIsLoading}
              endIcon={requestInvoiceCopyIsLoading ? <CircularProgress size={24} color='inherit' /> : null} 
            >
              Gerar 2ª via
            </Button>
          </Box>
        </form>
      </FormProvider>

     
    </Dialog>
  )
}